.drop-area {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.drop-area img {
    max-width: 100%;
    margin-top: 10px;
}