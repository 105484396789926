.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  /*justify-content: center;*/
  /*align-items: center;*/
  /*padding: 15px;*/

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  /*background-image: url('./../img/bg-01.jpg');*/
  /*background-color: #bbc6dd;*/
  background-color: #ffffff;
}

.header-align{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 31.8rem;
}

.header-align-sup{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 34.8rem;
}

.container-login100 input{
  height: calc(2.25rem + 2px) !important;
}

.login100-form-title {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  color: #0f0f0f;
  padding-left: 60px;
  line-height: 1.2;
  text-align: center;
  display: block;
}

.logo {
  width: 11rem;
}
.logo-container {
  /*margin: 2rem 0 3rem 0;*/
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content:start;
  align-items: start;
  font-size: 0.8em;
}

.erroAutenticacao {
  color: #e95b5b;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}
.success {
  color: black;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

body input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: #0e0d0d !important;
}

input:focus::-webkit-input-placeholder { color: #0e0d0d ; }
input:focus:-moz-placeholder { color:#0e0d0d; }
input:focus::-moz-placeholder { color:#0e0d0d; }
input:focus:-ms-input-placeholder { color:#0e0d0d; }

textarea:focus::-webkit-input-placeholder { color:#0e0d0d; }
textarea:focus:-moz-placeholder { color:#0e0d0d; }
textarea:focus::-moz-placeholder { color:#0e0d0d; }
textarea:focus:-ms-input-placeholder { color:#0e0d0d; }

input::-webkit-input-placeholder { color: rgb(19, 18, 18);}
input:-moz-placeholder { color: rgb(20, 20, 20);}
input::-moz-placeholder { color: rgb(12, 12, 12);}
input:-ms-input-placeholder { color: rgb(17, 16, 16);}

textarea::-webkit-input-placeholder { color: rgb(27, 27, 27);}
textarea:-moz-placeholder { color: rgb(19, 18, 18);}
textarea::-moz-placeholder { color: rgb(20, 20, 20);}
textarea:-ms-input-placeholder { color: rgb(12, 11, 11);}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}


/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  /*font-family: Poppins-Regular;*/
  font-size: 17px;
  color: #6699FF;
  line-height: 1.5;
  cursor: pointer;
}

.wrap-login100 {
  font-size: 1em;
  width: 450px;
  /*height: 550px;*/
  border-radius: 15px;
  overflow: hidden;
  padding: 55px 55px 37px 55px;

  justify-content: center;

  /*background: #fff;*/
  /*background: -webkit-linear-gradient(top, #99c3df, #7dc0e7);*/
  /*background: -o-linear-gradient(top, #99c3df, #7dc0e7);*/
  /*background: -moz-linear-gradient(top, #99c3df, #7dc0e7);*/
  /*background: linear-gradient(top, #99c3df, #7dc0e7);*/
}



.login100-form {
  width: 100%;
}

.login100-form-logo {
  font-size: 60px;
  color: #333333;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}


.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid rgba(7, 7, 7, 0.973);
  margin-bottom: 30px;
}

.input100 {
  /*font-family: Poppins-Regular;*/
  font-size: 1px;
  color: rgb(10, 10, 10);
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px 0 38px;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: rgb(13, 14, 14);
}

.focus-input100::after {
  /*font-family: Material-Design-Iconic-Font;*/
  font-size: 22px;
  color: rgba(255, 255, 255, 0.829);

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 6px;
  left: 0;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus {
  padding-left: 5px;
}

.input100:focus + .focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.has-val.input100 {
  padding-left: 5px;
}


/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  /*font-family: Poppins-Medium;*/
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 120px;
  height: 50px;
  border-radius: 25px;

  background: #03173f;
  /*background: -webkit-linear-gradient(bottom, #7579ff, #b224ef);*/
  /*background: -o-linear-gradient(bottom, #7579ff, #b224ef);*/
  /*background: -moz-linear-gradient(bottom, #7579ff, #b224ef);*/
  /*background: linear-gradient(bottom, #7579ff, #b224ef);*/
  position: relative;
  z-index: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background-color: #6699FF;
  top: 0;
  left: 0;
  opacity: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  color: #fffefef8;
}

.login100-form-btn:hover:before {
  opacity: 0;
}

.max-w-1280{
  max-width: 1366px;
}
.w-60per{
  width: 60%;
}
.m-h-hull-s{
  max-height: 100vh;
}
.h-full-s {
  height: 100vh;
}
.overflow-hidden{
  overflow: hidden;
}
.full-screen-container {
  width: 50%;
  height:100%;
  overflow: hidden;
  -webkit-box-shadow: 2px 0 4px 1px rgba(0,0,0,0.75);
  -moz-box-shadow:2px 0 4px 1px rgba(0,0,0,0.75);
  box-shadow: 2px 0 4px 1px rgba(0,0,0,0.75);
  background-image: url('./img/etrium-img12.jpg');
  background-repeat: no-repeat; /* Impede que a imagem se repita */
  background-size: cover;
  border-top-right-radius: .51rem;
  border-bottom-right-radius: .51rem;
}

.full-screen-image {
  width: 100%;
  height:100%;
  object-fit: fill; /* Isso faz com que a imagem se estique para preencher o contêiner, ignorando as proporções */
  /*border-radius: .5rem;*/
  /*border-top-right-radius: 1rem;*/
  /*border-bottom-right-radius: 1rem;*/
  background-image: url('./img/etrium-img-jus.jpg');
  background-repeat: no-repeat; /* Impede que a imagem se repita */
  background-size: cover;

  margin-right: 2px;
}
@media (max-width: 768px) {
  .full-screen-container{
    display: none;
  }
}
.container-logo-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
  width: 50%;
}
@media (max-width: 768px) {
  .container-logo-form {
    width: 100%;
  }
}
.form-s{
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.text-login-h1{
  font-size: 2.5rem;
  font-weight: bold;
  color: #0071c6;
  margin-bottom: 0.5rem;
}
.btn-login100 {
  width: 100%;
  justify-content: center;
  height: calc(2.25rem + 2px) !important;
}

.divider-login {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  height: 1px;
  background: #9d9d9d;
  position: relative;
}

.divider-text {
  position: absolute;
  top: -10px; /* Ajuste conforme necessário */
  background: #FFF; /* Supondo fundo branco */
  padding: 0 10px; /* Para garantir que o fundo cubra a linha */
}

.gap-1rem {
  gap: 1rem;
}

.font-size-13{
  font-size: 13px;
}
.link-etrium-login{
  color: #0071c6;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}
.flooting-button-suport {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border-radius: 999px;
  background-color: #0071c6;
  cursor: pointer;
  color: white;
  padding: .3rem;
}

